export const saveToLocalStorage = <T>(key: string, value: T): void => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const getFromLocalStorage = <T>(key: string): T | null => {
  const storedValue = localStorage.getItem(key);
  let result = null;
  try {
    result = storedValue ? (JSON.parse(storedValue) as T) : null;
  } catch (err) {
    console.warn(err);
  }
  return result;
};
